import React from 'react'
import { motion } from "framer-motion"

export default function ContactMe() {
  return (
    <section id="contact" className="contact-me flex gap-4 mx-3 mt-40">
        <div className="col flex-1">3</div>
      </section>
  )
}
